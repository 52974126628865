<template>
  <div class="user-profile--notifications">
    <h1>Notifications</h1>
    Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores,
    doloribus corporis. Deserunt cumque dolores, esse soluta repudiandae,
    aliquid neque possimus error repellat aspernatur animi quam ad quae quia.
    Earum, rerum!
  </div>
</template>

<script>
export default {
  name: 'UserNotifications',
}
</script>
